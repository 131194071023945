<template>
  <div class="home pt-4">
    <div
      :class="[
        'vinyl',
        'w-100',
        personalActive ? 'active-left' : 'active-right',
      ]"
    >
      <div :class="['side-overlay', 'side-overlay-left']"></div>
      <div :class="['side-overlay', 'side-overlay-right']"></div>
      <div class="vinyl-logo-cont">
        <div class="spinner-cont">
          <div class="spinner spinner2"></div>
          <div class="spinner"></div>
        </div>
        <b-img :src="require('@/assets/logo.svg')" />
      </div>
      <div class="circle-line">
        <svg
          version="1.1"
          class="circle-line"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 449.1 447.9"
          style="enable-background: new 0 0 449.1 447.9"
          xml:space="preserve"
        >
          <path class="st0" d="M449.1,442.5C204.7,442.5,6.6,244.4,6.6,0" />
        </svg>
      </div>
      <div
        :class="['side', 'side-left', 'pointer']"
        @mouseover="personalActive = true"
        @click="loginPersonal()"
      >
        <div class="title-cont">
          <div class="side-title">
            <span>{{ $t("personal_user") }}</span>
          </div>
          <div class="side-subtitle">
            <span>{{ $t("for") }}</span>
          </div>
        </div>
      </div>
      <div
        :class="['side', 'side-right', 'pointer']"
        @mouseover="personalActive = false"
        @click="loginGym()"
      >
        <div class="title-cont">
          <div class="side-title">
            <span>{{ $t("gyms") }}</span>
          </div>
          <div class="side-subtitle">
            <span>{{ $t("for") }}</span>
          </div>
        </div>
      </div>
    </div>
    <!--  <carousel3d type="channels" namevar="channels" goto="channel"/>
    <carousel3d type="playlists" namevar="playlists" goto="playlist"/>
    <carousel3d type="channels" namevar="soundboard" goto="soundboard"/>-->
  </div>
</template>

<script type="text/babel">
import Vue from "vue";
import carousel3d from "../components/carousel3d";

export default Vue.extend({
  created() {},
  data() {
    return {
      ready: false,
      personalActive: false,
    };
  },
  name: "home",
  components: {
    carousel3d,
  },

  methods: {
    loginPersonal() {
      this.$store.commit("auth/setLoginPersonal", true);
      this.$router.push("login");
    },
    loginGym() {
      this.$store.commit("auth/setLoginPersonal", false);
      this.$router.push("login");
    },
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
});
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
$color: red;
@keyframes dash {
  from {
    stroke-dashoffset: 4000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg) scale(1.004);
  }
  to {
    transform: rotate(180deg) scale(1.004);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#app .home {
  * {
    font-family: "Cairo", sans-serif !important;
  }
  background: black;
  width: 100vw;
  height: 100vh;
  .vinyl {
    border-radius: 50%;
    position: fixed;
    background: url(~@/assets/bg/gym.jpg);
    background-size: cover;
    height: 100vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1vw solid $color;
    overflow: hidden;
    max-width: 2000px;
    max-height: 2000px;
    max-width: 160vh;
    max-height: 160vh;
    &:after {
      width: 100%;
      height: 100%;
      position: absolute;
      content: "";
      /*background: black;*/
      mix-blend-mode: color;
    }
    .side {
      width: 50%;
      height: 100%;
      position: relative;
      z-index: 1;
      * {
        font-family: "Cairo", sans-serif !important;
      }
      .title-cont {
        position: relative;
        display: flex;
        flex-direction: column;
        place-items: flex-end;
        font-style: italic;
        span {
          transform: skewX(4deg);
          display: block;
        }
        .side-title {
          color: white;
          position: relative;
          display: inline-block;
          right: -1%;
          font-size: 2.5vw;
          text-transform: uppercase;
          font-weight: bold;
          padding: 1vw 1.5vw;

          &:before {
            background: black;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            top: -2%;
            left: 0;
            transform: skew(-6deg, 0deg);
          }
        }
        .side-subtitle {
          color: white;
          position: relative;

          display: inline-block;
          right: 0%;
          font-size: 1.5vw;
          text-transform: uppercase;
          font-weight: bold;
          padding: 0.4vw 1.2vw;
          margin-top: -0.1%;
          &:before {
            background: $color;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            top: -3%;
            left: 0;
            transform: skew(-6deg, 0deg);
          }
        }
      }
    }

    .side-overlay {
      width: 50%;
      height: 100%;
      position: absolute;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background: transparent;
        mix-blend-mode: color;
        transform: rotate(6deg);
        transition: 0.3s all linear;
      }
      &:after {
        opacity: 0;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-image: transparent;
        background-image: repeating-linear-gradient(
          90deg,
          #000000,
          #000000 40%,
          #00000000 40%,
          #00000000 50%
        );
        /* mix-blend-mode: color; */
        transform: rotate(6deg);
        background-size: 7% 100%;
      }
    }
    .vinyl-logo-cont {
      position: absolute;
      width: 32%;
      height: 32%;
      background: black;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1.6vw solid $color;
      img {
        width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .spinner-cont {
        width: 100%;
        height: 100%;
        transform: scale(1.004);
        animation: rotate 24s linear reverse infinite;
        .spinner {
          width: 100%;
          height: 100%;
          top: 0%;
          left: 0%;

          position: absolute;
          border-radius: 50%;

          border-width: 0.4vw;
          border-style: solid;
          border-color: rgb(0, 0, 0) transparent rgb(0, 0, 0) transparent;
          animation: spinner 75s linear infinite;
        }
        .spinner2 {
          border-color: rgb(255, 0, 0) transparent rgb(255, 0, 0) transparent;
          animation: spinner 75s linear reverse infinite;
        }
      }
    }
    .circle-line {
      height: 100%;
      width: 100%;
      position: absolute;
      svg {
        fill: none;
        stroke: white;
        height: 35%;
        position: absolute;
        top: 50%;
        stroke-width: 1%;
        left: 10%;
        width: 35%;
        transform: rotate(5deg);
      }
    }
    &.active-right {
      .side-overlay-right {
        &:before {
          background: $color;
        }
        &:after {
          opacity: 1;
        }
      }
    }
    &.active-left {
      .circle-line {
        svg {
          left: auto;
          right: 10%;

          transform: rotate(-96deg);
        }
      }
      .side-overlay-left {
        &:before {
          background: $color;
        }
        &:after {
          opacity: 1;
        }
      }
    }

    .side-overlay-right {
      right: 0;
      &:before {
        right: -0.5%;
      }
      &:after {
        right: -0.5%;
      }
    }
    .side-overlay-left {
      &:before {
        left: 0;
      }
      &:after {
        left: 0;
      }
    }
    .side-right {
      right: -50%;
      top: -100%;
      .title-cont {
        top: 80%;
        transform: translateY(-100%);
        place-items: flex-start;
        left: -5.5%;
      }
    }
    .side-left {
      top: 0;
      left: 0;

      .title-cont {
        top: 20%;
        right: -5%;
      }
    }
  }
}
@media (min-width: 2000px) {
  .vinyl {
    border: 15px solid $color !important;
    .side-title {
      padding: 15px 30px !important;
      font-size: 40px !important;
    }
    .side-subtitle {
      padding: 10px 25px !important;
      font-size: 27px !important;
    }
    .vinyl-logo-cont {
      border: 25px solid $color;
      .spinner-cont {
        .spinner {
          border-width: 7px;
        }
      }
    }
  }
}
</style>
