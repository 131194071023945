<template>
  <div class="relative car3d-cont">
    <h2 class="uppercase mt-2 mt-lg-4 pl-4 pl-lg-5 pt-3" :style="{fontSize: windowW<=991 ? '1.5rem' : '2rem'}">{{ $t(namevar) }}</h2>
    <div class="relative">
      <div style="" class="py-5 row">
        <carousel-3d
          :space="windowW * (windowW<991 ? 0.5 : 0.19)"
          :controls-visible="true"
          v-if="items.length > 0"
          :count="items.length"
          :disable3d="true"
          :height="windowW * 0.2" 
        >
          <slide :index="i" v-for="(item, i) in items" :key="i" class="pointer">
            <div @click.prevent="select_item(item)">
              <b-aspect :aspect="1" class="py-4">
                <div
                  class="img-place"
                  :style="{
                    background: `url(${
                      Array.isArray(item.image) ? item.image[0] : item.image
                    })`,
                  }"
                ></div>
              </b-aspect>
            </div>
            <div
              class="
                text-center
                py-4
                uppercase
                primary primary--text
                slide-title
              "
              style="mix-blend-mode: overlay"
            >
              <b>{{ item.name }}</b>
            </div>
          </slide>
        </carousel-3d>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import Vue from "vue";
import { apiClient } from "@/util/resources";
import Carousel3d from "vue-carousel-3d";
Vue.use(Carousel3d);
export default Vue.extend({
  props: ["type", "namevar", "goto"],
  data() {
    return {
      windowW: 0,
      windowH: 0,
    };
  },
  mounted() {
    this.get_sizes();
    window.addEventListener("resize", this.get_sizes);
    this.get_items();
  },
  computed: {
    channels: {
      get() {
        return this.$store.state.channels.channels;
      },
      set(value) {
        this.$store.commit("channels/setChannels", value);
      },
    },

    playlists: {
      get() {
        return this.$store.state.playlists.playlists;
      },
      set(value) {
        this.$store.commit("playlists/setPlaylists", value);
      },
    },

    items() {
      if (this.type === "channels") return this.channels;
      else if (this.type === "playlists") return this.playlists;
      else return [];
    },
  },
  watch: {},
  methods: {
    select_item(item) {
      this.$router.push({ name: this.goto, params: { id: item.id } });
    },
    get_sizes() {
      this.windowW = window.innerWidth;
      this.windowH = window.innerHeight;
    },
    get_items() {
      const this_ = this;
      apiClient
        .get(`api/${this_.type}`, {})
        .then((response) => {

          if (Array.isArray(response.data)) {
            //this_.$store.dispatch('channels/setChannels', response.data);
            if (this_.type === "channels") {
              this_.$store.commit("channels/setChannels", response.data);
              //this_.items = response.data;
            } else if (this_.type === "playlists") {
              this_.$store.commit("playlists/setPlaylists", response.data);
            } else {
              this_.$store.commit("channels/setChannels", response.data);
            }
          }
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t("_error"),
            variant: "danger",
            solid: true,
            noAutoHide: true,
            toaster: "b-toaster-top-center",
          });
        });
    },
  },
});
</script>
<style lang="scss" >

.car3d-cont {
  border-bottom: 1px solid black;

  .carousel-3d-container {
    overflow: visible !important;
    width: 75vw !important;
    .b-aspect {
      position: relative;
    }

    .carousel-3d-slider {
      width: 10vw !important;
    }
    .carousel-3d-slide {
      background: transparent;
      border-radius: 4px;
      border: 0;
      height: auto !important;
      width: 10vw !important;
      .b-aspect:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        mix-blend-mode: lighten;
      }
      img {
        filter: grayscale(1) drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
        filter: grayscale(1);
      }

      .slide-title {
        background: transparent;
        width: fit-content;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
      }
      &.current {
        overflow: visible;
        transform: scale(1.5) !important;
        .slide-title {
          font-size: 100% !important;
          margin-top: 0vw !important;
        }
      }
      .img-cont {
        position: relative;
      }
      .img-cont:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        border-radius: 50%;
      }
    }
    .img-place {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-size: cover;
    }
    
  }
  .carousel-3d-controls {
      .next,
      .prev {
        color: #77a8bb;
      }
    }
}

.badge-primary {
  color: #fff;
  background-color: #00607a;
}
@media (max-width: 991px) {
  .car3d-cont{

  
 .carousel-3d-container{
.carousel-3d-slide{
.slide-title{
  font-size:2vw;
}
&.current{
  transform: scale(1.8) !important;
  .slide-title{
    font-size: 2vw !important;
    padding-top: 0 !important;
    margin-top: -15% !important;
  }
}
 } 
  }

}
}
</style>
